import React, { useState } from 'react'
import { Container, Offcanvas } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Menu from '~components/Menu'

import logo from './img/logo.svg'

import * as s from './Header.module.scss'

const Header = ({ siteTitle }) => {
  const [menu, setMenu] = useState(false)

  const handleMenu = () => {
    setMenu((prev) => !prev)
  }

  return (
    <header className={s.header}>
      <Container className={s.header__inner}>
        <a href="/" className={s.header__logo}>
          <img src={logo} width="47" height="50" alt={siteTitle} />
        </a>

        <Offcanvas show={menu} placement="top" backdrop={false} responsive="md">
          <Menu variant="header" onClose={menu && (() => setMenu(false))} />
        </Offcanvas>

        <button
          type="button"
          aria-label="Menu"
          className={s.hamb}
          aria-expanded={menu}
          onClick={handleMenu}
        >
          <span />
        </button>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  siteTitle: '',
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
