import React from 'react'
import PropTypes from 'prop-types'

import { Nav } from 'react-bootstrap'

import MENU from './constants'

const Menu = ({ variant, onClose }) => {
  const handleScroll = (e) => {
    e.preventDefault()

    const el = document.querySelector(e?.target?.getAttribute('href'))

    if (onClose) onClose()
    setTimeout(
      () => {
        el?.scrollIntoView({
          behavior: 'smooth',
        })
      },
      onClose ? 200 : 0
    )
  }

  return (
    <Nav className={variant && `nav--${variant}`} as="ul">
      {MENU.map(({ name, link }) => (
        <Nav.Item as="li" key={name}>
          {link.includes('#') ? (
            <Nav.Link href={link} onClick={handleScroll}>
              {name}
            </Nav.Link>
          ) : (
            <Nav.Link href={link} target="_blank">
              {name}
            </Nav.Link>
          )}
        </Nav.Item>
      ))}
    </Nav>
  )
}

Menu.defaultProps = {
  variant: '',
}

Menu.propTypes = {
  variant: PropTypes.string,
}

export default Menu
