const SOCIAL = [
  {
    name: 'Twitter',
    link: 'https://twitter.com/prophet_node',
  },
  {
    name: 'Medium',
    link: 'https://medium.com/@prophet.one',
  },
]

export default SOCIAL
