import React from 'react'

import * as s from './Terms.module.scss'

const Terms = () => {
  return (
    <p className={s.terms}>
      <a href="../privacy_policy.pdf" target="_blank">
        Privacy policy
      </a>{' '}
      &{' '}
      <a href="../terms_of_service.pdf" target="_blank">
        Terms of service
      </a>
    </p>
  )
}

export default Terms
