import SOCIAL from '../Social/constants'

const MENU = [
  {
    name: 'Stake',
    link: '#stake',
  },
  ...SOCIAL,
  {
    name: 'Contact Us',
    link: '#contact',
  },
]

export default MENU
